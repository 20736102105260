<template>
  <div
    class="ProjectOptionListItem"
    :class="rootClasses"
    @click="onClick">
    <div class="LeftSide">
      <div
        class="IconWrap"
        :class="{ IsComplete: item.isComplete }">
        <CategoryIcon
          v-if="item.categoryID"
          :categoryID="item.categoryID" />
        <components
          v-else-if="item.icon"
          :is="item.icon" />
        <div
          v-if="item.isComplete"
          class="CheckIcon">
          <CheckIcon />
        </div>
      </div>
      <div class="Info">
        <span
          v-if="item.preText"
          class="SubText">{{ item.preText }}</span>
        <span class="MainText">{{ item.mainText }}</span>
        <span
          v-if="item.subText"
          class="SubText">{{ item.subText }}</span>
      </div>
    </div>
    <div class="OpenIcon">
      <RightIcon />
    </div>
  </div>
</template>

<script>
import CheckIcon from '@/assets/svg/check.svg?inline'
import RightIcon from '@/assets/svg/with-padding/angle-right.svg?inline'
import CategoryIcon from '@/components/Icons/CategoryIcon.vue'

export default {
  name: 'ProjectOptionListItem',
  props: {
    item: {
      type: Object,
      required: true,
    },
    showGreenIcon: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  computed: {
    rootClasses() {
      return {
        IsDisabled: this.item.isDisabled,
        ShowGreenIcon: this.showGreenIcon,
      }
    },
  },
  methods: {
    onClick() {
      this.$emit('item-click', this.item.id)
    },
  },
  components: {
    CheckIcon,
    RightIcon,
    CategoryIcon,
  },
}
</script>

<style lang="stylus" scoped>
  .ProjectOptionListItem
    display flex
    align-items center
    justify-content space-between
    padding 10px 0px 10px 0px
    border-bottom 1px solid $color_grey_lighter
    cursor pointer
    .LeftSide
      display flex
      align-items center
      .IconWrap
        overflow hidden
        border-radius 4px
        position relative
        box(50px)
        padding 5px
        background-color $color_grey_lightest
        margin-right 10px
        svg
          fill lighten($color_grey_dark, 10%)
        &.IsComplete
          background-color lighten($color_primary, 75%)
        .CheckIcon
          position absolute
          top 1px
          right 1px
          border-radius 4px
          box(20px)
          padding 3px
          background-color rgba(#fff, 0.85)
          svg
            fill $color_primary
      .Info
        .MainText
          font-weight bold
          text-transform uppercase
        .SubText
          color $color_grey
          font-size 0.875rem
    .OpenIcon
      display flex
      box(50px)
      padding 8px
    &.ShowGreenIcon
      .LeftSide
        .IconWrap
          svg
            fill $color_primary
    &.IsDisabled
      pointer-events none
      .LeftSide
        .IconWrap
          svg
            fill $color_grey_light
        .Info
          .MainText
            color $color_grey
          .SubText
            color $color_grey_light
      .OpenIcon
        svg
          fill $color_grey_lighter
</style>
