<template>
  <div class="NextStepLine">
    <div
      class="NeedCompleteText"
      v-if="showNeedCompleteText">
      <span>{{ mixWB('COMPLETE_THE_PAGE_TO_CONTINUED') }}</span>
    </div>
    <div
      class="LineWrap"
      :class="rootClasses"
      @click="onNextStepClick()">
      <ProjectOptionListItem
        :item="nextItem"
        :showGreenIcon="nextStep.icon === 'check'" />
    </div>

    <Dialog
      :isShowing="showMissingUnitsModal"
      :useComponent="MissingUnitsModal"
      :componentProps="{
        missingCategoryIDs,
      }"
      @close="showMissingUnitsModal=false"
      @back-click="showMissingUnitsModal=false"
      @complete-screening-click="onCompleteScreeningClick" />
  </div>
</template>

<script>
import ProjectOptionListItem from '@/components/UpdateFlow/ProjectOptionListItem.vue'
import MissingUnitsModal from '@/components/ScreeningItems/MissingUnitsModal.vue'
import { mapGetters } from 'vuex'
import Dialog from '@/components/Modals/Dialog.vue'

import FloorplanIcon from '@/assets/svg/categories/plinth.svg?inline'
import CheckIcon from '@/assets/svg/with-padding/check.svg?inline'
import CameraIcon from '@/assets/svg/with-padding/camera.svg?inline'
import PCBIcon from '@/assets/svg/with-padding/pcb.svg?inline'
import SamplingIcon from '@/assets/svg/with-padding/case.svg?inline'
import BackArrowIcon from '@/assets/svg/with-padding/arrow-back.svg?inline'

export default {
  name: 'NextStepLine',
  props: {
    isDisabled: {
      type: Boolean,
      required: false,
      default: false,
    },
    showTopBorder: {
      type: Boolean,
      required: false,
      default: false,
    },
    showNeedCompleteText: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return {
      MissingUnitsModal,
      isButtonLoading: false,
      showMissingUnitsModal: false,
      missingCategoryIDs: [],
      // Icons
      CheckIcon,
      FloorplanIcon,
      CameraIcon,
      PCBIcon,
      SamplingIcon,
      BackArrowIcon,
    }
  },
  computed: {
    ...mapGetters([
      'nextStep',
      'selectedUnits',
      'categoriesAsArray',
      'screeningTypes',
      'skippedCategories',
    ]),
    rootClasses() {
      return {
        IsDisabled: this.isDisabled,
        ShowTopBorder: this.showTopBorder,
      }
    },
    nextStepPreText() {
      if (this.nextStep.action === 'go-back') {
        return ''
      }
      return this.nextStep.action === 'go-to-overview'
        ? this.mixWB('DONE') : this.mixWB('NEXT')
    },
    nextItem() {
      const data = {
        id: 'units',
        icon: null,
        categoryID: null,
        isDisabled: false,
        isComplete: false,
        preText: this.nextStepPreText,
        mainText: this.nextStep.text,
        to: this.nextStep.path,
      }

      // Set icon
      switch (this.nextStep.icon) {
      case 'check':
        data.icon = CheckIcon
        break
      case 'back':
        data.icon = BackArrowIcon
        break
      case 'floorplan':
        data.icon = FloorplanIcon
        break
      case 'camera':
        data.icon = CameraIcon
        break
      case 'pcb':
        data.icon = PCBIcon
        break
      case 'sampling':
        data.icon = SamplingIcon
        break

      default:
        break
      }

      // Set category icon
      if (this.nextStep.icon.includes('C-')) {
        data.categoryID = this.nextStep.icon
      }

      return data
    },
  },
  methods: {
    onNextStepClick() {
      if (this.nextStep.action === 'complete-unit') {
        this.$router.push(this.nextStep.path)
        this.$store.dispatch('setCurrentScreeningSelectedFilterUnitID', null)
        return
      }

      if (this.nextStep.action === 'complete-screening') {
        const allUnitsDone = this.checkAllUnits()
        if (!allUnitsDone) {
          return
        }

        this.onCompleteScreeningClick()
        return
      }

      this.$router.push(this.nextStep.path)
    },
    async onCompleteScreeningClick() {
      this.showMissingUnitsModal = false
      this.isButtonLoading = true

      // Force store update
      await this.$store.dispatch('forceUpdateScreeningCollections')

      this.$store.dispatch('updateScreening', { setAsCompleted: true, updateTestResultStatus: true })

      this.$router.push(this.nextStep.path)
    },
    checkAllUnits() {
      if (this.selectedUnits.length === 1) {
        return true
      }

      const status = this.selectedUnits.reduce((prev, unit) => {
        const missingCategoryIDs = this.categoriesAsArray.reduce((prev2, category) => {
          // Find types for this category
          let types = this.screeningTypes.filter(
            (x) => x.categoryID === category.id
            && x.unitIDs.includes(unit.id),
          )

          // Find types for connected categories
          if (category.connection) {
            const connectedTypes = this.screeningTypes.filter(
              (x) => category.connection.categoryIDs.includes(x.categoryID)
              && category.connection.categoryTypeIDs.includes(x.type.typeID)
              && x.unitIDs.includes(unit.id),
            )
            types = types.concat(connectedTypes)
          }

          // Check for skipped
          let isSkipped = this.skippedCategories.general.includes(category.id)
          if (!isSkipped) {
            isSkipped = this.skippedCategories[
              unit.id
            ].includes(category.id)
          }

          if (!types.length && !isSkipped) {
            prev2.push(category.id)
          }

          return prev2
        }, [])

        if (missingCategoryIDs.length) {
          prev.allGood = false
        }

        prev.missingCategoryIDs[unit.id] = missingCategoryIDs

        return prev
      }, {
        allGood: true,
        missingCategoryIDs: {},
      })

      if (!status.allGood) {
        this.missingCategoryIDs = status.missingCategoryIDs
        this.showMissingUnitsModal = true
      }

      return status.allGood
    },
  },
  components: {
    ProjectOptionListItem,
    Dialog,
  },

}
</script>

<style lang="stylus" scoped>
  .NextStepLine
    margin-bottom 25px
    .NeedCompleteText
      padding-bottom 5px
      span
        color $color_grey
    .LineWrap
      &.IsDisabled
        pointer-events none
        >>> > div > div
          opacity 0.5
      &.ShowTopBorder
        border-top 1px solid $color_grey_lighter
</style>
